<template>
  <div>
    <div class="d-flex align-center">
      <h1 class="text-h4">Заказ {{ item.code }} от {{ item.orderDate }}</h1>
      <a class="d-block ml-1" :href="orderSiteLink" target="_blank"><v-icon>mdi-open-in-new</v-icon></a>
    </div>
    <v-divider class="my-2"></v-divider>
    <v-row dense>
      <v-col cols="12" md="8" order="2" order-md="1">
        <order-manage-card :order="item" class="mb-1"/>
        <order-customer-card :order="item"/>

        <order-delivery-card v-if="isDelivery" :order="item" class="mt-1" />
        <order-pickup-card v-if="isPickup" :order="item" class="mt-1" />
      </v-col>
      <v-col cols="12" md="4" order="1" order-md="2">
        <order-status-card :order="item" @onSelect="setStatusAction"/>
        <order-brief-card :order="item" class="mt-1"/>
        <order-actions-card :order="item" class="mt-1" @apply-discount-code="addOrderDiscount" />
      </v-col>
    </v-row>
    <order-discount-card v-if="item.discount" :order="item" class="mt-1" @delete-discount="deleteOrderDiscount"/>
    <order-lines-card :order="item" :disabled="busy" class="mt-1" />
    <order-images-card class="mt-1" />
    <meta-view
      :id="item.id"
      :loading="busy"
      :created-at="item.meta.createdAt"
      :updated-at="item.meta.updatedAt"
    ></meta-view>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'
import MetaView from '@/components/ui/MetaView.vue'
import OrderCustomerCard from '@/pages/order/components/OrderCustomerCard.vue'
import OrderDeliveryCard from '@/pages/order/components/OrderDeliveryCard.vue'
import OrderPickupCard from '@/pages/order/components/OrderPickupCard.vue'
import OrderBriefCard from '@/pages/order/components/OrderBriefCard.vue'
import OrderLinesCard from '@/pages/order/components/OrderLinesCard.vue'
import OrderStatusCard from '@/pages/order/components/OrderStatusCard.vue'
import OrderActionsCard from '@/pages/order/components/OrderActionsCard.vue'
import ToastService from '@/service/ToastService'
import OrderDiscountCard from '@/pages/order/components/OrderDiscountCard.vue'
import OrderManageCard from '@/pages/order/components/OrderManageCard.vue'
import OrderImagesCard from '@/pages/order/components/OrderImagesCard.vue'
import configs from '@/configs'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderPage',
  components: { OrderImagesCard, OrderManageCard, OrderDiscountCard, OrderActionsCard, OrderStatusCard, OrderLinesCard, OrderBriefCard, OrderPickupCard, OrderDeliveryCard, OrderCustomerCard, MetaView },
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('order', ['item', 'busy']),
    isDelivery() {
      return this.item.deliveryType === 'Delivery'
    },
    isPickup() {
      return this.item.deliveryType === 'Pickup'
    },
    // deliveryAddress() {
    //   const v = []
    //
    //   v.push(this.item.deliveryAddressCity)
    //   v.push(this.item.deliveryAddressStreet)
    //   v.push(this.item.deliveryAddressStreetNumber)
    //   v.push(this.item.deliveryAddressApartment)
    //
    //   return v.filter((i) => i !== null).join(', ')
    // },
    deliveryShop() {
      const v = []

      v.push(this.item.deliveryShop.name)
      v.push(this.item.deliveryShop.address)

      return v.filter((i) => i !== null).join(' - ')
    },
    orderSiteLink() {
      return `${configs.siteUrl}/order/${this.id}`
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then()
  },
  methods: {
    ...mapActions('order', ['fetchData','setOrderStatus','fetchItem', 'addDiscount', 'deleteDiscount']),
    setStatusAction(value) {
      this.setOrderStatus({ id: this.id, orderStatus: value.value })
      ToastService.success('Статус изменен')
    },
    deleteOrderDiscount() {
      this.deleteDiscount()
        .then(() => ToastService.success('Скидка удалена'))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    addOrderDiscount(code) {
      this.addDiscount({ code })
        .then(() => ToastService.success('Скидка применена'))
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Заказ ' + this.item.code
      }
    }
  }
}

</script>
